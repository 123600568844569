export enum TruckType {
    Import = 1,
    Delivery = 2,
}

export const enumLabel = (value?: TruckType) => {
    switch (value) {
        case TruckType.Import:
            return 'Импорт';
        case TruckType.Delivery:
            return 'Доставка';
        default:
            return '';
    }
};