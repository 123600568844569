import React, { useState, useEffect } from 'react';
import { notification } from 'antd';

import { Input, Form, Button } from 'antd';
import jwt_decode from 'jwt-decode';

import { exception } from '@extensions/notification';

import { useAppDispatch } from '@store/hooks';
import { userLoaded } from '@store/actions';

import { serverFetch } from '@src/core/server';

import { IUser } from '@entities/user';
import { IUserSession } from '@entities/user-session';
import { IContact } from '@entities/contact';

import { UserType } from '@enums/user-type';

import { MainLogoIcon } from '@icons/index';

import GooglePlay from '@assets/img/google-play-badge.png';
import GooglePlayQr from '@assets/img/GooglePlayQr.png';

import AppStore from '@assets/img/app-store-badge.svg';
import AppStoreQr from '@assets/img/AppStoreQr.png';

import '@src/core/auth.css';

const Auth = () => {
    const d = useAppDispatch();

    const [signInForm] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const [loading, setLoading] = useState<boolean>(false);
    const [systemSupport, setSystemSupport] = useState<IContact>();

    useEffect(() => {
        getSystemSupport();

        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
            close();
        };
    }, []);

    const getSystemSupport = () => {
        console.log('getSystemSupport');

        serverFetch(`contacts/systemsupport`, { method: 'GET' })
            .then((data) => {
                setSystemSupport(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения контакта технической поддержки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            //signInForm.submit();
        }
    };

    const close = () => {
        setLoading(false);
        signInForm.resetFields();
    };

    const onFinish = (entity: IUser) => {
        setLoading(true);

        entity.isWeb = true;

        serverFetch(`auth/mysignin`, { method: 'POST', bodyData: entity, credentials: 'include' })
            .then((data) => {
                window.accessToken = data.accessToken;

                localStorage.setItem('refreshToken', data.refreshToken);

                var result: any = jwt_decode(data.accessToken);

                let userSession: IUserSession = {
                    userId: result.id,
                    fullName: result.fullName,
                    type: +result.type as UserType,
                    permissions: JSON.parse(result.permissions),
                    userSettings: JSON.parse(result.userSettings),
                    resetPasswordRequired: data.resetPasswordRequired,
                    isDemo: data.isDemo,
                };

                d(userLoaded(userSession));
                close();
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка авторизации', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <div>
            <div className='auth'>
                <div className='auth-qr-container'>
                    <div>
                        <a href='https://play.google.com/store/apps/details?id=ru.freshlinecargo.my'>
                            <img style={{ width: 170 }} alt='Доступно в Google Play' src={GooglePlay} />
                        </a>
                        <div>
                            <img src={GooglePlayQr} style={{ width: 100 }} />
                        </div>
                    </div>
                    <div style={{ marginLeft: 30 }}>
                        <a href='https://apps.apple.com/app/my-freshline/id6642688980'>
                            <img style={{ width: 170, height: 50, margin: 8 }} alt='Доступно на  App Store' src={AppStore} />
                        </a>
                        <div>
                            <img src={AppStoreQr} style={{ width: 100 }} />
                        </div>
                    </div>
                </div>

                <div className='auth-container'>
                    <div className='auth-form'>
                        <div className='auth-logo'>
                            <MainLogoIcon style={{ fontSize: 64 }} />
                        </div>
                        <div className='auth-form-title'>Личный кабинет</div>

                        <Form size={'large'} form={signInForm} initialValues={{ remember: true }} onFinish={onFinish}>
                            <Form.Item
                                name='login'
                                rules={[{ required: true, message: 'Пожалуйста введите логин' }]}
                                hasFeedback
                                className='auth-form-item'
                            >
                                <Input name='login' placeholder='Логин' disabled={loading} className='auth-form-item-input' />
                            </Form.Item>
                            <Form.Item
                                name='password'
                                className='auth-form-item'
                                rules={[{ required: true, message: 'Пожалуйста введите пароль' }]}
                                hasFeedback
                            >
                                <Input.Password
                                    name='password'
                                    placeholder={'Пароль'}
                                    disabled={loading}
                                    className='auth-form-item-input'
                                />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Button type='primary' htmlType='submit' loading={loading} className='auth-form-btn'>
                                    Войти
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div className='auth-support-container'>
                        <div className='auth-support'>
                            Техническая поддержка
                            <div className='auth-support-phone'> {systemSupport ? systemSupport.phone : '+7-905-097-60-68'}</div>
                        </div>
                    </div>
                </div>

                {contextHolder}
            </div>
        </div>
    );
};

export default Auth;
