export enum DeliveryOption {
    Truck = 1,
    Pickup = 2,
    Airplane = 3,
}

export const enumLabel = (value?: DeliveryOption) => {
    switch (value) {
        case DeliveryOption.Truck:
            return 'Автоперевозка';
        case DeliveryOption.Airplane:
            return 'Авиаперевозка';
        case DeliveryOption.Pickup:
            return 'Самовывоз';

        default:
            return '';
    }
};
