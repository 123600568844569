import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

import { Space, Button, Layout, Menu, Avatar, Badge, Flex, Tag, Tooltip, notification } from 'antd';
import { UserOutlined, MessageFilled, BellOutlined, HistoryOutlined } from '@ant-design/icons';

import { useAppSelector, useAppDispatch } from '@store/hooks';
import { setCurrentMenuItem, userLoaded, setRestartServiceRequired } from '@store/actions';

import { exception } from '@extensions/notification';

import { toFinanceString } from '@extensions/utils';

import { serverFetch } from '@src/core/server';
import '@src/core/index.css';

import { IUserSession } from '@entities/user-session';
import { IUserBalance } from '@entities/user-balance';
import { IContact } from '@entities/contact';
import { IUserFilter } from '@entities/user-filter';
import { UserType } from '@enums/user-type';

import {
    LogisticsIcon,
    CreditCardIcon,
    ImportTruckIcon,
    DeliveryTruckIcon,
    BoxesIcon,
    ContactsIcon,
    BillIcon,
    MainLogoIcon,
} from '@icons/index';

const MainLayout = () => {
    const [api, contextHolder] = notification.useNotification();

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const { Header, Content, Sider } = Layout;

    const location = useLocation();

    const d = useAppDispatch();
    const currentMenuItem = useAppSelector<string>((s) => s.currentMenuItem);
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [newNotificationsQty, setNewNotificationsQty] = useState<number | undefined>();
    const [systemSupport, setSystemSupport] = useState<IContact>();
    const [balance, setBalance] = useState<IUserBalance>();

    function getItem(label: any, key: string, urlTo?: string, icon?: any, children?: Array<any>) {
        let itemlabel = urlTo ? <Link to={urlTo}>{label}</Link> : label;

        return {
            key,
            icon,
            children,
            label: itemlabel,
        };
    }

    useEffect(() => {
        let items = location.pathname.split('/');
        let key = items[1];

        d(setCurrentMenuItem(key));

        loadBalance();
        getSystemSupport();
        getNewNotifications();

        const interval = setInterval(() => {
            getNewNotifications();
        }, 5000); //per 5 sec

        return () => clearInterval(interval);
    }, []);

    const loadBalance = () => {
        setBalance(undefined);

        let filter: IUserFilter = { ids: [userSession.userId], isArchived: false, isActive: true };
        serverFetch('users/balances', { method: 'GET', queryParams: filter })
            .then((data) => {
                setBalance(data[0]);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения балансов', ex, () => d(userLoaded(undefined)));
            });
    };

    const getSystemSupport = () => {
        console.log('getSystemSupport');

        serverFetch(`contacts/systemsupport`, { method: 'GET' })
            .then((data) => {
                setSystemSupport(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения контакта технической поддержки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onLogout = () => {
        serverFetch(`auth/logout`, { method: 'POST' }).then(() => {
            d(userLoaded(undefined));
        });
    };

    const getNewNotifications = async () => {
        d(setRestartServiceRequired(false));

        await serverFetch('notifications/unread', { method: 'GET' })
            .then((data) => {
                setNewNotificationsQty(data);
            })
            .catch((ex) => {
                setNewNotificationsQty(undefined);
            });
    };

    const onMenuClick = (e: any) => {
        d(setCurrentMenuItem(e.key));
    };

    const items: Array<any> = [];
    if (userSession.type === UserType.MainClient || userSession.type === UserType.SubClient) {
        items.push(getItem('Заказы', 'orders', '/orders', <LogisticsIcon />));
    } else if (userSession.type === UserType.Supplier || userSession.type === UserType.Employee) {
        items.push(getItem('Импорт', 'import', '/import', <ImportTruckIcon />));
        items.push(getItem('Доставка', 'delivery', '/delivery', <DeliveryTruckIcon />));
        items.push(getItem('Остатки', 'remainings', '/remainings', <BoxesIcon />));
    }

    if (userSession.userSettings?.showBills) {
        items.push(getItem('Счета', 'billheaders', 'billheaders', <BillIcon />));
    }

    if (userSession.userSettings?.showNotifications) {
        items.push(getItem('Уведомления', 'notifications', 'notifications', <MessageFilled />));
    }

    items.push(getItem('Профиль', 'useraccount', '/useraccount', <UserOutlined />));
/* 
    if (userSession.type === UserType.MainClient || userSession.type === UserType.Supplier) {
        items.push(getItem('Балансы', 'balances', '/balances', <CreditCardIcon style={{ fontSize: 22 }} />));
        items.push(getItem('История операций', 'transactions', '/transactions', <HistoryOutlined />));
    } */

    items.push(getItem('Контакты', 'contacts', 'contacts', <ContactsIcon />));

    return (
        <Layout style={{ height: '100vh', minHeight: '100vh' }}>
            <Sider>
                <div className='logo'>
                    <MainLogoIcon style={{ fontSize: 48, display: 'table-cell', verticalAlign: 'middle' }} />
                </div>
                {items && <Menu selectedKeys={[currentMenuItem]} mode='inline' items={items} onClick={onMenuClick} />}

                <div className='support-container'>
                    <div className='support'>
                        Тех. поддержка
                        <div className='support-phone'>{systemSupport ? systemSupport.phone : '+7-905-097-60-68'}</div>
                    </div>
                </div>
            </Sider>
            <Layout>
                <Header className='main-header'>
                    <Space style={{ float: 'right' }}>
                       {/*  <Flex justify='flex-start' align='center' gap='middle'>
                            {balance && (
                                <a onClick={() => navigate(`/balances`)}>
                                    <Tooltip placement='bottom' title='Баланс счета'>
                                        <Flex justify='center' align='center'>
                                            <Tag
                                                style={{
                                                    padding: '5px 10px 7px',
                                                    marginLeft: 2,
                                                    fontSize: 24,
                                                    fontWeight: 600,
                                                    color: '#FFFFFF',
                                                    background: balance.amount >= 0 ? '#294e3f' : 'var(--main-red)',
                                                }}
                                            >
                                                {toFinanceString(balance.amount)} $
                                            </Tag>
                                            <Tag
                                                style={{
                                                    padding: '5px 10px 7px',
                                                    marginLeft: 2,
                                                    fontSize: 24,
                                                    fontWeight: 600,
                                                    color: '#FFFFFF',
                                                    background: '#294e3f',
                                                }}
                                            >
                                                {toFinanceString(2000)} €
                                            </Tag>
                                        </Flex>
                                    </Tooltip>
                                </a>
                            )}
                        </Flex> */}
                        {userSession.userSettings?.showNotifications && (
                            <Space>
                                <Button
                                    shape='circle'
                                    size='large'
                                    type='text'
                                    icon={
                                        <>
                                            <BellOutlined style={{ fontSize: 22, marginBottom: -3 }} />
                                            <Badge
                                                count={newNotificationsQty}
                                                overflowCount={10}
                                                size='small'
                                                style={{
                                                    marginLeft: -10,
                                                    marginBottom: 25,
                                                }}
                                            />
                                        </>
                                    }
                                    onClick={() => {
                                        d(setCurrentMenuItem('notifications'));
                                        navigate(`/notifications`);
                                    }}
                                ></Button>
                            </Space>
                        )}
                        <Space className='user-account-info'>
                            <Avatar
                                style={{ backgroundColor: '#fff' }}
                                size='large'
                                src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                            />
                            <div className='user'>{userSession.fullName}</div>
                        </Space>
                        <Button type='text' className='logout' onClick={() => onLogout()}>
                            Выход
                        </Button>
                    </Space>
                </Header>
                <Content className='main-content'>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
