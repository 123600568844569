export enum RouteZone {
    Foreign = 1,
    Internal = 2,
}

export const enumLabel = (value?: RouteZone) => {
    switch (value) {
        case RouteZone.Foreign:
            return 'Зарубежный маршрут';
        case RouteZone.Internal:
            return 'Внутренний маршрут';
        default:
            return '';
    }
};
