export enum TruckStatus {
    New = 1,
    OnWay = 2,
    Finished = 3,
    Cancelled = 4,
}

export const enumLabel = (value?: TruckStatus) => {
    switch (value) {
        case TruckStatus.New:
            return 'Новый';
        case TruckStatus.OnWay:
            return 'В пути';
        case TruckStatus.Finished:
            return 'Завершен';
        case TruckStatus.Cancelled:
            return 'Отменен';
        default:
            return '';
    }
};
