import React from 'react';
import Icon from '@ant-design/icons';

const MainLogoIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 , ...props.style }} />;
};
export default MainLogoIcon;

const svg = ({ ...props }) => (
    <svg
        {...props}
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width='100%'
        viewBox='0 0 1124 339'
        enableBackground='new 0 0 1124 339'
    >
        <path
            fill='transparent'
            opacity='1.000000'
            stroke='none'
            d='
M802.000000,340.000000 
   C534.666687,340.000000 267.833344,340.000000 1.000000,340.000000 
   C1.000000,227.000000 1.000000,114.000000 1.000000,1.000000 
   C375.666656,1.000000 750.333313,1.000000 1125.000000,1.000000 
   C1125.000000,114.000000 1125.000000,227.000000 1125.000000,340.000000 
   C1017.500000,340.000000 910.000000,340.000000 802.000000,340.000000 
M623.868530,231.018524 
   C623.868530,253.955124 623.868530,276.891724 623.868530,299.986755 
   C633.796997,299.986755 643.193665,299.986755 653.151001,299.986755 
   C653.151001,276.753418 653.151001,253.756668 653.151001,230.759903 
   C653.587036,230.513336 654.023132,230.266785 654.459167,230.020218 
   C670.752502,246.324738 687.045837,262.629272 702.694519,278.288696 
   C709.304504,271.737183 716.001282,265.099701 723.462952,257.704102 
   C707.494812,241.753952 691.217896,225.495377 674.503174,208.799438 
   C697.941162,208.799438 720.990784,208.799438 743.804321,208.799438 
   C743.804321,198.891403 743.804321,189.482224 743.804321,179.649338 
   C720.585632,179.649338 697.727478,179.649338 674.869385,179.649338 
   C674.630798,179.264267 674.392212,178.879196 674.153625,178.494141 
   C690.523376,162.260361 706.893127,146.026581 722.645447,130.405090 
   C715.750977,123.491951 709.084045,116.806953 701.710632,109.413559 
   C685.762878,125.360916 669.495728,141.627716 652.771667,158.351410 
   C652.771667,134.871796 652.771667,111.824921 652.771667,88.801590 
   C642.991821,88.801590 633.703674,88.801590 623.788269,88.801590 
   C623.788269,112.263145 623.788269,135.535828 623.257019,158.478058 
   C607.068298,141.989227 590.879517,125.500412 575.218506,109.549095 
   C567.596558,117.084229 560.912598,123.692009 553.127014,131.388855 
   C569.097229,147.351456 585.321777,163.568283 601.938416,180.176971 
   C578.697144,180.176971 555.794495,180.176971 532.880127,180.176971 
   C532.880127,190.017319 532.880127,199.378632 532.880127,209.234924 
   C556.196411,209.234924 579.052185,209.234924 603.417480,209.234924 
   C586.190491,226.418442 569.974365,242.593643 553.998169,258.529480 
   C560.870728,265.377625 567.529297,272.012512 575.356384,279.811768 
   C576.207947,278.499420 577.044128,276.560394 578.431519,275.172363 
   C593.499878,260.097443 608.654663,245.108856 623.868530,231.018524 
M466.909851,100.133171 
   C455.154083,101.601280 445.594208,106.845428 438.154449,116.580597 
   C438.154449,98.707191 438.154449,80.833794 438.154449,62.726227 
   C428.241821,62.726227 418.815765,62.726227 409.343750,62.726227 
   C409.343750,111.647118 409.343750,160.325745 409.343750,208.755493 
   C419.138916,208.755493 428.549927,208.755493 438.123413,208.755493 
   C438.123413,190.075409 437.814484,171.753143 438.285797,153.450943 
   C438.458008,146.763382 439.647034,139.827133 441.875824,133.537140 
   C444.648132,125.713226 453.214630,122.174805 463.137115,123.188370 
   C471.811676,124.074463 476.248444,128.006485 478.067963,137.304153 
   C478.955170,141.837860 479.204346,146.551392 479.238647,151.187241 
   C479.367981,168.677643 479.290649,186.169601 479.290680,203.660965 
   C479.290680,205.400055 479.290680,207.139145 479.290680,208.759766 
   C489.491150,208.759766 498.890991,208.759766 507.917145,208.759766 
   C507.917145,182.942123 508.600739,157.463623 507.678162,132.043427 
   C507.057098,114.931046 498.020874,104.784958 483.180847,101.501503 
   C478.198822,100.399200 472.946167,100.519981 466.909851,100.133171 
M1050.764160,200.294876 
   C1058.069092,193.829025 1062.487671,185.669449 1065.426514,175.996445 
   C1057.371704,175.996445 1049.891968,175.881882 1042.422607,176.109131 
   C1041.176270,176.147049 1039.580933,177.406464 1038.814819,178.549286 
   C1032.273926,188.306992 1019.399475,192.304260 1006.768127,188.326370 
   C996.381592,185.055420 990.680237,175.898544 991.264282,162.958740 
   C1016.489624,162.958740 1041.739380,162.958740 1067.701050,162.958740 
   C1067.457275,157.720718 1067.418579,152.926865 1066.983765,148.169220 
   C1063.677368,112.001205 1030.616333,90.812180 996.619873,103.013695 
   C973.781799,111.210411 960.551941,132.452530 961.842346,159.513214 
   C962.830811,180.240936 970.797913,197.472366 990.554077,206.156921 
   C1011.002869,215.145950 1031.383667,214.126053 1050.764160,200.294876 
M274.927338,204.518066 
   C285.672699,198.089401 291.423248,188.155563 295.237183,176.160812 
   C287.156128,176.160812 279.673187,176.044647 272.200867,176.274460 
   C270.946472,176.313034 269.344971,177.584518 268.570129,178.735031 
   C261.854523,188.706879 248.838577,192.678528 236.074173,188.480255 
   C225.966125,185.155655 220.488113,176.081146 221.097458,163.110352 
   C246.296707,163.110352 271.527161,163.110352 297.659302,163.110352 
   C297.060242,156.122406 296.970215,149.635880 295.880554,143.321869 
   C291.653168,118.826988 274.350098,102.453598 250.787811,100.212921 
   C226.095047,97.864738 203.781433,111.355240 195.257309,133.920471 
   C191.289597,144.423889 190.347672,155.341629 192.085983,166.374100 
   C195.278259,186.634109 205.362000,201.939606 225.431366,208.635300 
   C242.022797,214.170654 258.550659,213.212097 274.927338,204.518066 
M894.520630,124.680779 
   C912.445190,119.024765 922.350037,125.966713 923.044128,144.691299 
   C923.136597,147.186493 923.154724,149.685547 923.156494,152.182831 
   C923.166626,166.503799 923.161377,180.824753 923.161377,195.145721 
   C923.161377,199.598633 923.161438,204.051544 923.161438,208.730209 
   C933.071655,208.730209 942.490479,208.730209 953.107056,208.730209 
   C952.369568,181.197388 952.226196,153.761902 950.624512,126.411819 
   C949.938904,114.704453 942.479065,106.029358 930.923401,102.319901 
   C914.018982,96.893456 892.613708,99.419403 881.800964,116.268715 
   C881.724976,116.387054 881.245911,116.246681 880.424316,116.191444 
   C880.424316,111.823082 880.424316,107.429230 880.424316,103.088165 
   C870.985840,103.088165 862.082520,103.088165 853.134216,103.088165 
   C853.134216,138.462799 853.134216,173.515869 853.134216,208.747253 
   C862.790833,208.747253 872.194031,208.747253 882.035461,208.747253 
   C882.035461,193.918182 881.928467,179.308411 882.078674,164.701263 
   C882.158936,156.893158 882.276001,149.044968 883.126709,141.297241 
   C883.894714,134.302917 886.759338,128.132217 894.520630,124.680779 
M361.971191,122.213791 
   C364.851196,125.752457 367.731171,129.291122 371.322357,133.703598 
   C378.611603,133.703598 387.692871,133.703598 396.906891,133.703598 
   C395.411835,118.396355 387.472351,108.425476 373.328369,103.642441 
   C368.490540,102.006447 363.322998,101.017708 358.229736,100.528870 
   C345.323853,99.290192 332.538025,100.031319 320.645142,105.851471 
   C307.879089,112.098930 303.004913,122.911949 304.020721,136.652328 
   C304.909119,148.669052 312.948578,154.714996 323.230255,158.416611 
   C328.528687,160.324142 334.098389,161.471649 339.529236,163.019623 
   C347.669281,165.339813 356.012787,167.152191 363.865753,170.215988 
   C371.737671,173.287201 372.896820,182.150589 366.664246,187.541534 
   C364.951630,189.022873 362.792542,190.185089 360.643188,190.932693 
   C345.818298,196.089157 333.982300,190.165787 328.258087,174.690491 
   C323.857635,174.690491 319.370697,174.690506 314.883759,174.690506 
   C310.458038,174.690506 306.032318,174.690491 301.583893,174.690491 
   C302.497864,192.416550 312.294189,204.345200 329.286133,208.989532 
   C341.360931,212.289871 353.613098,212.687225 365.930939,210.692642 
   C377.110138,208.882431 387.089783,204.739975 393.940948,195.133072 
   C406.061096,178.137848 400.003754,157.234055 380.701202,149.633606 
   C376.850891,148.117538 372.807831,147.058151 368.803894,145.971558 
   C359.195557,143.364105 349.473297,141.138412 339.969543,138.200531 
   C335.640228,136.862213 332.592499,133.569641 333.129517,128.375351 
   C333.563324,124.179626 337.154083,120.423157 342.566406,120.298279 
   C348.771393,120.155113 355.006989,121.340759 361.971191,122.213791 
M103.537491,102.955345 
   C99.231163,102.310226 101.438148,98.943497 101.475876,97.045044 
   C101.676407,86.954895 104.509415,84.451500 114.539215,84.656281 
   C117.305504,84.712769 120.073921,84.664886 122.695038,84.664886 
   C122.695038,77.402458 122.695038,70.359612 122.695038,62.944553 
   C115.128860,62.944553 107.959091,62.535919 100.851707,63.028156 
   C85.226746,64.110283 75.027443,73.685745 72.704094,89.155899 
   C72.044983,93.544632 72.092087,98.039421 71.781059,103.036018 
   C66.156013,103.036018 60.599762,103.036018 55.010014,103.036018 
   C55.010014,109.723480 55.010014,115.850616 55.010014,122.598404 
   C60.728291,122.598404 66.275673,122.598404 72.296722,122.598404 
   C72.296722,151.777008 72.296722,180.336380 72.296722,208.798431 
   C82.206627,208.798431 91.612206,208.798431 101.393661,208.798431 
   C101.393661,180.046265 101.393661,151.614822 101.393661,122.408768 
   C108.272980,122.408768 114.807358,122.408768 121.242424,122.408768 
   C121.242424,115.509186 121.242424,109.391541 121.242424,102.961037 
   C115.494850,102.961037 110.014084,102.961037 103.537491,102.955345 
M792.636536,95.500198 
   C792.636536,84.447960 792.636536,73.395714 792.636536,62.529758 
   C782.318115,62.529758 772.919373,62.529758 763.465515,62.529758 
   C763.465515,111.443977 763.465515,159.975952 763.465515,208.631119 
   C773.249817,208.631119 782.771362,208.631119 792.636475,208.631119 
   C792.636475,171.020386 792.636475,133.760300 792.636536,95.500198 
M126.284874,150.499847 
   C126.284874,169.933884 126.284874,189.367905 126.284874,208.879395 
   C136.345810,208.879395 145.777008,208.879395 155.715118,208.879395 
   C155.715118,206.858124 155.707001,205.064011 155.716400,203.269974 
   C155.801086,187.114273 155.403015,170.937546 156.101532,154.808334 
   C156.931793,135.637558 169.956573,125.192848 188.905090,127.476982 
   C190.789215,127.704094 192.685715,127.828697 194.609421,128.003876 
   C194.609421,118.649269 194.609421,109.904625 194.609421,101.075829 
   C186.129471,98.740822 178.126526,100.140213 171.386703,104.655487 
   C164.917679,108.989334 159.526489,114.932037 153.791519,120.051796 
   C153.791519,114.917122 153.791519,109.006874 153.791519,103.285858 
   C144.460098,103.285858 135.548279,103.285858 126.284882,103.285858 
   C126.284882,118.907173 126.284882,134.203506 126.284874,150.499847 
M808.473145,206.086823 
   C809.073364,207.045303 809.657288,208.826447 810.276245,208.838730 
   C819.354919,209.018372 828.438232,208.962296 837.470520,208.962296 
   C837.470520,173.193863 837.470520,138.146317 837.470520,103.023010 
   C827.717529,103.023010 818.290283,103.023010 808.469666,103.023010 
   C808.469666,137.246811 808.469666,171.200134 808.473145,206.086823 
z'
        />
        <path
            fill='#FFFEFE'
            opacity='1.000000'
            stroke='none'
            d='
M623.788269,158.808502 
   C623.788269,135.535828 623.788269,112.263145 623.788269,88.801590 
   C633.703674,88.801590 642.991821,88.801590 652.771667,88.801590 
   C652.771667,111.824921 652.771667,134.871796 652.771667,158.351410 
   C669.495728,141.627716 685.762878,125.360916 701.710632,109.413559 
   C709.084045,116.806953 715.750977,123.491951 722.645447,130.405090 
   C706.893127,146.026581 690.523376,162.260361 674.153625,178.494141 
   C674.392212,178.879196 674.630798,179.264267 674.869385,179.649338 
   C697.727478,179.649338 720.585632,179.649338 743.804321,179.649338 
   C743.804321,189.482224 743.804321,198.891403 743.804321,208.799438 
   C720.990784,208.799438 697.941162,208.799438 674.503174,208.799438 
   C691.217896,225.495377 707.494812,241.753952 723.462952,257.704102 
   C716.001282,265.099701 709.304504,271.737183 702.694519,278.288696 
   C687.045837,262.629272 670.752502,246.324738 654.459167,230.020218 
   C654.023132,230.266785 653.587036,230.513336 653.151001,230.759903 
   C653.151001,253.756668 653.151001,276.753418 653.151001,299.986755 
   C643.193665,299.986755 633.796997,299.986755 623.868530,299.986755 
   C623.868530,276.891724 623.868530,253.955124 624.009399,230.262436 
   C635.794434,217.894272 647.438721,206.282181 659.863281,193.891968 
   C647.639160,182.003845 635.713745,170.406174 623.788269,158.808502 
z'
        />
        <path
            fill='#DF1039'
            opacity='1.000000'
            stroke='none'
            d='
M623.522644,158.643280 
   C635.713745,170.406174 647.639160,182.003845 659.863281,193.891968 
   C647.438721,206.282181 635.794434,217.894272 623.968018,229.801544 
   C608.654663,245.108856 593.499878,260.097443 578.431519,275.172363 
   C577.044128,276.560394 576.207947,278.499420 575.356384,279.811768 
   C567.529297,272.012512 560.870728,265.377625 553.998169,258.529480 
   C569.974365,242.593643 586.190491,226.418442 603.417480,209.234924 
   C579.052185,209.234924 556.196411,209.234924 532.880127,209.234924 
   C532.880127,199.378632 532.880127,190.017319 532.880127,180.176971 
   C555.794495,180.176971 578.697144,180.176971 601.938416,180.176971 
   C585.321777,163.568283 569.097229,147.351456 553.127014,131.388855 
   C560.912598,123.692009 567.596558,117.084229 575.218506,109.549095 
   C590.879517,125.500412 607.068298,141.989227 623.522644,158.643280 
z'
        />
        <path
            fill='#FDFDFD'
            opacity='1.000000'
            stroke='none'
            d='
M467.363525,100.107452 
   C472.946167,100.519981 478.198822,100.399200 483.180847,101.501503 
   C498.020874,104.784958 507.057098,114.931046 507.678162,132.043427 
   C508.600739,157.463623 507.917145,182.942123 507.917145,208.759766 
   C498.890991,208.759766 489.491150,208.759766 479.290680,208.759766 
   C479.290680,207.139145 479.290680,205.400055 479.290680,203.660965 
   C479.290649,186.169601 479.367981,168.677643 479.238647,151.187241 
   C479.204346,146.551392 478.955170,141.837860 478.067963,137.304153 
   C476.248444,128.006485 471.811676,124.074463 463.137115,123.188370 
   C453.214630,122.174805 444.648132,125.713226 441.875824,133.537140 
   C439.647034,139.827133 438.458008,146.763382 438.285797,153.450943 
   C437.814484,171.753143 438.123413,190.075409 438.123413,208.755493 
   C428.549927,208.755493 419.138916,208.755493 409.343750,208.755493 
   C409.343750,160.325745 409.343750,111.647118 409.343750,62.726227 
   C418.815765,62.726227 428.241821,62.726227 438.154449,62.726227 
   C438.154449,80.833794 438.154449,98.707191 438.154449,116.580597 
   C445.594208,106.845428 455.154083,101.601280 467.363525,100.107452 
z'
        />
        <path
            fill='#FDFDFD'
            opacity='1.000000'
            stroke='none'
            d='
M1050.496582,200.530502 
   C1031.383667,214.126053 1011.002869,215.145950 990.554077,206.156921 
   C970.797913,197.472366 962.830811,180.240936 961.842346,159.513214 
   C960.551941,132.452530 973.781799,111.210411 996.619873,103.013695 
   C1030.616333,90.812180 1063.677368,112.001205 1066.983765,148.169220 
   C1067.418579,152.926865 1067.457275,157.720718 1067.701050,162.958740 
   C1041.739380,162.958740 1016.489624,162.958740 991.264282,162.958740 
   C990.680237,175.898544 996.381592,185.055420 1006.768127,188.326370 
   C1019.399475,192.304260 1032.273926,188.306992 1038.814819,178.549286 
   C1039.580933,177.406464 1041.176270,176.147049 1042.422607,176.109131 
   C1049.891968,175.881882 1057.371704,175.996445 1065.426514,175.996445 
   C1062.487671,185.669449 1058.069092,193.829025 1050.496582,200.530502 
M1035.525879,135.163696 
   C1030.160645,123.825470 1021.106201,119.597015 1008.499573,122.542374 
   C998.091125,124.974152 991.239319,133.513657 991.398376,143.910828 
   C1006.864868,143.910828 1022.329163,143.910828 1038.228882,143.910828 
   C1037.344604,140.915619 1036.599365,138.391785 1035.525879,135.163696 
z'
        />
        <path
            fill='#FDFDFD'
            opacity='1.000000'
            stroke='none'
            d='
M274.614258,204.711975 
   C258.550659,213.212097 242.022797,214.170654 225.431366,208.635300 
   C205.362000,201.939606 195.278259,186.634109 192.085983,166.374100 
   C190.347672,155.341629 191.289597,144.423889 195.257309,133.920471 
   C203.781433,111.355240 226.095047,97.864738 250.787811,100.212921 
   C274.350098,102.453598 291.653168,118.826988 295.880554,143.321869 
   C296.970215,149.635880 297.060242,156.122406 297.659302,163.110352 
   C271.527161,163.110352 246.296707,163.110352 221.097458,163.110352 
   C220.488113,176.081146 225.966125,185.155655 236.074173,188.480255 
   C248.838577,192.678528 261.854523,188.706879 268.570129,178.735031 
   C269.344971,177.584518 270.946472,176.313034 272.200867,176.274460 
   C279.673187,176.044647 287.156128,176.160812 295.237183,176.160812 
   C291.423248,188.155563 285.672699,198.089401 274.614258,204.711975 
M260.110962,127.348694 
   C252.606094,121.262802 240.998886,120.238838 232.455338,124.908974 
   C224.930801,129.022079 220.524948,136.742935 221.369400,144.382187 
   C236.742416,144.382187 252.117737,144.382187 269.220734,144.382187 
   C266.022156,138.241180 263.321533,133.056229 260.110962,127.348694 
z'
        />
        <path
            fill='#FEFEFE'
            opacity='1.000000'
            stroke='none'
            d='
M894.159546,124.826340 
   C886.759338,128.132217 883.894714,134.302917 883.126709,141.297241 
   C882.276001,149.044968 882.158936,156.893158 882.078674,164.701263 
   C881.928467,179.308411 882.035461,193.918182 882.035461,208.747253 
   C872.194031,208.747253 862.790833,208.747253 853.134216,208.747253 
   C853.134216,173.515869 853.134216,138.462799 853.134216,103.088165 
   C862.082520,103.088165 870.985840,103.088165 880.424316,103.088165 
   C880.424316,107.429230 880.424316,111.823082 880.424316,116.191444 
   C881.245911,116.246681 881.724976,116.387054 881.800964,116.268715 
   C892.613708,99.419403 914.018982,96.893456 930.923401,102.319901 
   C942.479065,106.029358 949.938904,114.704453 950.624512,126.411819 
   C952.226196,153.761902 952.369568,181.197388 953.107056,208.730209 
   C942.490479,208.730209 933.071655,208.730209 923.161438,208.730209 
   C923.161438,204.051544 923.161377,199.598633 923.161377,195.145721 
   C923.161377,180.824753 923.166626,166.503799 923.156494,152.182831 
   C923.154724,149.685547 923.136597,147.186493 923.044128,144.691299 
   C922.350037,125.966713 912.445190,119.024765 894.159546,124.826340 
z'
        />
        <path
            fill='#FDFDFD'
            opacity='1.000000'
            stroke='none'
            d='
M361.600250,122.081459 
   C355.006989,121.340759 348.771393,120.155113 342.566406,120.298279 
   C337.154083,120.423157 333.563324,124.179626 333.129517,128.375351 
   C332.592499,133.569641 335.640228,136.862213 339.969543,138.200531 
   C349.473297,141.138412 359.195557,143.364105 368.803894,145.971558 
   C372.807831,147.058151 376.850891,148.117538 380.701202,149.633606 
   C400.003754,157.234055 406.061096,178.137848 393.940948,195.133072 
   C387.089783,204.739975 377.110138,208.882431 365.930939,210.692642 
   C353.613098,212.687225 341.360931,212.289871 329.286133,208.989532 
   C312.294189,204.345200 302.497864,192.416550 301.583893,174.690491 
   C306.032318,174.690491 310.458038,174.690506 314.883759,174.690506 
   C319.370697,174.690506 323.857635,174.690491 328.258087,174.690491 
   C333.982300,190.165787 345.818298,196.089157 360.643188,190.932693 
   C362.792542,190.185089 364.951630,189.022873 366.664246,187.541534 
   C372.896820,182.150589 371.737671,173.287201 363.865753,170.215988 
   C356.012787,167.152191 347.669281,165.339813 339.529236,163.019623 
   C334.098389,161.471649 328.528687,160.324142 323.230255,158.416611 
   C312.948578,154.714996 304.909119,148.669052 304.020721,136.652328 
   C303.004913,122.911949 307.879089,112.098930 320.645142,105.851471 
   C332.538025,100.031319 345.323853,99.290192 358.229736,100.528870 
   C363.322998,101.017708 368.490540,102.006447 373.328369,103.642441 
   C387.472351,108.425476 395.411835,118.396355 396.906891,133.703598 
   C387.692871,133.703598 378.611603,133.703598 371.322357,133.703598 
   C367.731171,129.291122 364.851196,125.752457 361.600250,122.081459 
z'
        />
        <path
            fill='#FCFDFD'
            opacity='1.000000'
            stroke='none'
            d='
M104.035400,102.958191 
   C110.014084,102.961037 115.494850,102.961037 121.242424,102.961037 
   C121.242424,109.391541 121.242424,115.509186 121.242424,122.408768 
   C114.807358,122.408768 108.272980,122.408768 101.393661,122.408768 
   C101.393661,151.614822 101.393661,180.046265 101.393661,208.798431 
   C91.612206,208.798431 82.206627,208.798431 72.296722,208.798431 
   C72.296722,180.336380 72.296722,151.777008 72.296722,122.598404 
   C66.275673,122.598404 60.728291,122.598404 55.010014,122.598404 
   C55.010014,115.850616 55.010014,109.723480 55.010014,103.036018 
   C60.599762,103.036018 66.156013,103.036018 71.781059,103.036018 
   C72.092087,98.039421 72.044983,93.544632 72.704094,89.155899 
   C75.027443,73.685745 85.226746,64.110283 100.851707,63.028156 
   C107.959091,62.535919 115.128860,62.944553 122.695038,62.944553 
   C122.695038,70.359612 122.695038,77.402458 122.695038,84.664886 
   C120.073921,84.664886 117.305504,84.712769 114.539215,84.656281 
   C104.509415,84.451500 101.676407,86.954895 101.475876,97.045044 
   C101.438148,98.943497 99.231163,102.310226 104.035400,102.958191 
z'
        />
        <path
            fill='#F9FAFA'
            opacity='1.000000'
            stroke='none'
            d='
M792.636475,96.000198 
   C792.636475,133.760300 792.636475,171.020386 792.636475,208.631119 
   C782.771362,208.631119 773.249817,208.631119 763.465515,208.631119 
   C763.465515,159.975952 763.465515,111.443977 763.465515,62.529758 
   C772.919373,62.529758 782.318115,62.529758 792.636536,62.529758 
   C792.636536,73.395714 792.636536,84.447960 792.636475,96.000198 
z'
        />
        <path
            fill='#FBFCFB'
            opacity='1.000000'
            stroke='none'
            d='
M126.284882,149.999847 
   C126.284882,134.203506 126.284882,118.907173 126.284882,103.285858 
   C135.548279,103.285858 144.460098,103.285858 153.791519,103.285858 
   C153.791519,109.006874 153.791519,114.917122 153.791519,120.051796 
   C159.526489,114.932037 164.917679,108.989334 171.386703,104.655487 
   C178.126526,100.140213 186.129471,98.740822 194.609421,101.075829 
   C194.609421,109.904625 194.609421,118.649269 194.609421,128.003876 
   C192.685715,127.828697 190.789215,127.704094 188.905090,127.476982 
   C169.956573,125.192848 156.931793,135.637558 156.101532,154.808334 
   C155.403015,170.937546 155.801086,187.114273 155.716400,203.269974 
   C155.707001,205.064011 155.715118,206.858124 155.715118,208.879395 
   C145.777008,208.879395 136.345810,208.879395 126.284874,208.879395 
   C126.284874,189.367905 126.284874,169.933884 126.284882,149.999847 
z'
        />
        <path
            fill='#F9FAFA'
            opacity='1.000000'
            stroke='none'
            d='
M808.471436,205.620132 
   C808.469666,171.200134 808.469666,137.246811 808.469666,103.023010 
   C818.290283,103.023010 827.717529,103.023010 837.470520,103.023010 
   C837.470520,138.146317 837.470520,173.193863 837.470520,208.962296 
   C828.438232,208.962296 819.354919,209.018372 810.276245,208.838730 
   C809.657288,208.826447 809.073364,207.045303 808.471436,205.620132 
z'
        />
        <path
            fill='#20533E'
            opacity='1.000000'
            stroke='none'
            d='
M1035.690063,135.515808 
   C1036.599365,138.391785 1037.344604,140.915619 1038.228882,143.910828 
   C1022.329163,143.910828 1006.864868,143.910828 991.398376,143.910828 
   C991.239319,133.513657 998.091125,124.974152 1008.499573,122.542374 
   C1021.106201,119.597015 1030.160645,123.825470 1035.690063,135.515808 
z'
        />
        <path
            fill='#255642'
            opacity='1.000000'
            stroke='none'
            d='
M260.365936,127.609985 
   C263.321533,133.056229 266.022156,138.241180 269.220734,144.382187 
   C252.117737,144.382187 236.742416,144.382187 221.369400,144.382187 
   C220.524948,136.742935 224.930801,129.022079 232.455338,124.908974 
   C240.998886,120.238838 252.606094,121.262802 260.365936,127.609985 
z'
        />
    </svg>
);
