import React from 'react';
import Icon from '@ant-design/icons';

const BoxesIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 , ...props.style }} />;
};
export default BoxesIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 419.736 419.736'>
        <g id='SVGRepo_bgCarrier'></g>
        <g id='SVGRepo_tracerCarrier'></g>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <path d='M179.869,224.831h-48.533c-8.271,0-15,6.729-15,15v10.375c0,2.757-2.243,5-5,5H83.533c-2.757,0-5-2.243-5-5v-10.375 c0-8.271-6.729-15-15-15H15c-8.271,0-15,6.729-15,15v143.741c0,8.271,6.729,15,15,15h164.869c8.271,0,15-6.729,15-15V239.831 C194.869,231.56,188.14,224.831,179.869,224.831z M111.474,325.611v41.261H83.392v-41.259H68.066l29.37-33.118l29.368,33.116 H111.474z'></path>
                <path d='M404.736,224.831h-48.533c-8.271,0-15,6.729-15,15v10.375c0,2.757-2.243,5-5,5H308.4c-2.757,0-5-2.243-5-5v-10.375 c0-8.271-6.729-15-15-15h-48.533c-8.271,0-15,6.729-15,15v143.741c0,8.271,6.729,15,15,15h164.869c8.271,0,15-6.729,15-15V239.831 C419.736,231.56,413.008,224.831,404.736,224.831z M336.341,325.611v41.261h-28.082v-41.259h-15.326l29.37-33.118l29.368,33.116 H336.341z'></path>
                <path d='M127.434,194.905h164.869c8.271,0,15-6.729,15-15V36.164c0-8.271-6.729-15-15-15H243.77c-8.271,0-15,6.729-15,15v10.375 c0,2.757-2.243,5-5,5h-27.803c-2.757,0-5-2.243-5-5V36.164c0-8.271-6.729-15-15-15h-48.533c-8.271,0-15,6.729-15,15v143.741 C112.434,188.176,119.163,194.905,127.434,194.905z M209.869,88.829l29.368,33.116h-15.33v41.261h-28.082v-41.259h-15.326 L209.869,88.829z'></path>
            </g>
        </g>
    </svg>
);
