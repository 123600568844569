export enum TransactionStatus {
    Pending = 1,
    Authorised = 2,
    Canceled = 3,
}

export const enumLabel = (value?: TransactionStatus) => {
    switch (value) {
        case TransactionStatus.Pending:
            return 'В ожидании';
        case TransactionStatus.Authorised:
            return 'Авторизована';
        case TransactionStatus.Canceled:
            return 'Отменена';
        default:
            return '';
    }
};
