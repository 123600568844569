import React, { useState, useEffect } from 'react';
import { notification } from 'antd';

import { Input, Form, Modal, Button, Space } from 'antd';

import { exception } from '@extensions/notification';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { userLoaded } from '@store/actions';

import { serverFetch } from '@src/core/server';

import '@src/core/auth.css';

import { IUserSession } from '@entities/user-session';
import { IResetPassword } from '@entities/reset-password';

import { MainLogoIcon } from '@icons/index';

const ResetPassword = () => {
    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [signInForm] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const [loading, setLoading] = useState<boolean>(false);

    const close = () => {
        setLoading(false);
        signInForm.resetFields();
    };

    const onFinish = (entity: IResetPassword) => {
        setLoading(true);

        serverFetch(`users/resetpassword`, { method: 'POST', bodyData: entity })
            .then(() => {
                d(userLoaded({ ...userSession, resetPasswordRequired: false }));
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка смены пароля', ex, null);
            });
    };

    return (
        <div>
            <div className='auth'>
                <div className='auth-container'>
                    <div className='auth-form'>
                        <div className='auth-logo'>
                            <MainLogoIcon style={{ fontSize: 64 }} />
                        </div>
                        <div className='auth-form-title'>Изменить пароль</div>

                        <Form size={'large'} form={signInForm} initialValues={{ remember: true }} onFinish={onFinish}>
                            <Form.Item
                                name='oldPassword'
                                className='auth-form-item'
                                rules={[{ required: true, message: 'Укажите текущий пароль' }]}
                                hasFeedback
                            >
                                <Input.Password
                                    name='oldPassword'
                                    placeholder={'Текущий пароль'}
                                    disabled={loading}
                                    className='auth-form-item-input'
                                />
                            </Form.Item>

                            <Form.Item
                                name='newPassword'
                                className='auth-form-item'
                                rules={[{ required: true, message: 'Укажите новый пароль' }]}
                                hasFeedback
                            >
                                <Input.Password
                                    name='newPassword'
                                    placeholder={'Новый пароль'}
                                    disabled={loading}
                                    className='auth-form-item-input'
                                />
                            </Form.Item>

                            <Form.Item
                                name='confirmPassword'
                                className='auth-form-item'
                                rules={[
                                    { required: true, message: 'Подтвердите пароль' },
                                    {
                                        validator: async (_, value) => {
                                            if (value && signInForm.getFieldValue('newPassword') !== value)
                                                return Promise.reject('Пароли не совпадают');

                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    name='confirmPassword'
                                    placeholder={'Подтверждение пароля'}
                                    disabled={loading}
                                    className='auth-form-item-input'
                                />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Button type='primary' htmlType='submit' loading={loading} className='auth-form-btn'>
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>

            {contextHolder}
        </div>
    );
};

export default ResetPassword;
