import React from 'react';

import GooglePlay from '@assets/img/google-play-badge.png';
import AppStore from '@assets/img/app-store-badge.svg';

import './not-support.css';

import { MainLogoIcon } from '@icons/index';

const NotSupport = () => {
    return (
        <div className='not-support'>
            <div className='not-support-title'>
                <MainLogoIcon style={{ fontSize: 64 }} />
            </div>
            <div>
                <div>
                    <a href='https://play.google.com/store/apps/details?id=ru.freshlinecargo.my'>
                        <img style={{ width: 200, height: 85 }} alt='Доступно в Google Play' src={GooglePlay} />
                    </a>
                </div>
                <div style={{ marginTop: 20 }}>
                    <a href='https://apps.apple.com/app/my-freshline/id6642688980'>
                        <img style={{ width: 200 }} alt='Доступно на  App Store' src={AppStore} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NotSupport;
