import React, { useState, useEffect } from 'react';

import { Steps } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import { notification } from 'antd';

import { userLoaded } from '@store/actions';

import { exception } from '@extensions/notification';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IWaypoint } from '@entities/waypoint';
import { ITrackpoint } from '@entities/trackpoint';

import { WaypointType, enumLabel as waypointTypeLabel } from '@enums/waypoint-type';
import { RouteZone } from '@enums/route-zone';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IPoint {
    waypointid: string | undefined;
    title: string;
    description?: string;
    subTitle?: string;
}

const Trackpoints = ({ ...props }) => {
    const [api, contextHolder] = notification.useNotification();

    const { truckId } = props;

    const d = useAppDispatch();

    const [baseWaypoints, setBaseWaypoints] = useState<Array<IWaypoint>>([]);
    const [points, setPoints] = useState<Array<IPoint>>([]);
    const [trackpoints, setTrackpoints] = useState<Array<ITrackpoint>>([]);
    const [currentindex, setCurrentIndex] = useState<number>(-1);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setCurrentIndex(-1);
        setPoints([]);
        setTrackpoints([]);
        setBaseWaypoints([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`trackpoints/${truckId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маршрутных точек', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('waypoints', { method: 'GET', queryParams: { routeZone: RouteZone.Internal } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения путевых точек', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setTrackpoints(result[0][0]);
                setBaseWaypoints(result[0][1]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        let points: Array<IPoint> = [];

        let tmpTrackpoints = [...trackpoints];
        let lastTrackPoint: ITrackpoint | undefined = tmpTrackpoints.pop();

        if (baseWaypoints.length > 0) {
            baseWaypoints.map((w) => {
                let title: any = '';

                let trackpoint = trackpoints.find((t) => t.waypointId === w.id);

                if (w.type === WaypointType.Onway) {
                    title = <CarOutlined />;
                } else {
                    title = `${w.name ? w.name : ''} (${waypointTypeLabel(w.type)})`;
                }

                let point: IPoint = { waypointid: w.id, title: title };

                if (trackpoint) {
                    point.description = trackpoint.comment;
                    point.subTitle = trackpoint && trackpoint.date && dayjs.utc(trackpoint.date).local().format('DD.MM.YYYY');
                }

                points.push(point);
            });

            if (lastTrackPoint) {
                let waypoint = baseWaypoints.find((w) => w.id === lastTrackPoint?.waypointId);

                if (waypoint) {
                    let index = baseWaypoints.indexOf(waypoint);
                    setCurrentIndex(index);
                }
            }

            setPoints(points);
        }
    }, [baseWaypoints, trackpoints]);

    return (
        <>
            <div style={{ marginTop: 20 }}>
                {!loading && <Steps progressDot direction='vertical' current={currentindex} items={points} />}
            </div>

            {contextHolder}
        </>
    );
};

export default Trackpoints;
