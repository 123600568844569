import React from 'react';

import { Flex, Space, Button, Tooltip, Divider } from 'antd';
import { ClearOutlined } from '@ant-design/icons';

interface IFilter {
    items: Array<any>;
    onClear: () => void;
}

const Filter = (props: IFilter) => {
    return (
        <>
            <Divider style={{ marginTop: 0, marginBottom: 10 }} />
            <Flex justify='space-between' style={{ marginBottom: 15 }}>
                {props.items && (
                    <Space wrap>
                        {props.items.map((item) => {
                            return item;
                        })}
                    </Space>
                )}
                <Space direction='vertical'>
                    <Tooltip placement='left' title='Очистить фильтр'>
                        <Button icon={<ClearOutlined />} onClick={props.onClear} size='middle'></Button>
                    </Tooltip>
                </Space>
            </Flex>
        </>
    );
};

export default Filter;
