import { encode, decode } from 'base64-ts';
import { IEnumItem } from '@enums/enum-item';
import { RcFile } from 'antd/es/upload';

export function sanitize(text: string, allowedTags: Array<string> = ['b', 'i', 'u', 'br', 'br', 'div']) {
    return (
        text &&
        text.replace(/<[^>]*>/gm, (tag, a, b, c, d) => {
            let at = allowedTags.find((x) => tag.startsWith('<' + x));
            if (at) return '<' + at + '>';
            at = allowedTags.find((x) => tag === '</' + x + '>');
            if (at) return tag;
            return '';
        })
    );
}

export function capitalizeFirstLetter(string: string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
}

export function distinct(arr: Array<any>) {
    let distinctArray: Array<any> = [];
    arr.forEach((x) => !distinctArray.includes(x) && distinctArray.push(x));
    return distinctArray;
}

export function getBase64Blob(file: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export const getBase64RcFile = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export async function files2ServerFormat(files: Array<Blob> = []) {
    console.log('files2ServerFormat', files);

    let fileBodies: Array<any> = [];
    await Promise.all(
        files.map((f, idx) => getBase64Blob(f).then((base64: any) => (fileBodies[idx] = base64.substr(base64.indexOf('base64,') + 7))))
    );

    return files.map((f, idx) => ({
        fileName: f.name,
        fileExtension: f.name.slice(-3),
        fileSize: f.size,
        pageNumber: idx,
        fileBody: fileBodies[idx],
        url: undefined,
        documentDataType: f.type.startsWith('image')
            ? 'Image'
            : f.type.startsWith('video')
            ? 'Video'
            : f.type === 'application/pdf'
            ? 'Pdf'
            : 'None',
    }));
}

export function jsonParse(value: string, errValue = undefined) {
    let retval = errValue;
    try {
        retval = JSON.parse(value);
    } catch (err) {
        console.error('json parse error', value, err);
    }
    return retval;
}

export function object2Array(obj: any, keyName: string) {
    return Object.keys(obj).map((k) => ({ ...obj[k], [keyName]: k }));
}

export const zeroTime = (d: Date | undefined) => d && new Date(d.toDateString());

export const today = () => zeroTime(new Date());

export const get = (array: Array<any> = [], func: Function) => {
    var result;
    Object.values(array).forEach((item) => {
        if (func(item)) {
            result = item;
        }
    });
    return result;
};

export const toUpperCase = (value: string) => {
    if (!value || typeof value !== 'string') return null;

    return value.toUpperCase();
};

export const dataURItoBlob = (dataURI: string) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
};

export const getBoolean = (value: string) => {
    if (!value) return false;

    return value.toUpperCase() === 'TRUE';
};

export const arrayToBase64 = (array: Array<string>) => {
    let utf8Encoder = new TextEncoder();
    return encode(utf8Encoder.encode(JSON.stringify(array)));
};

export const base64ToArray = (value: string) => {
    let utf8Decoder = new TextDecoder();
    let toJson = utf8Decoder.decode(decode(value));
    let arr = JSON.parse(toJson);

    return arr;
};

export const formatPhone = (phone: string) => {
    let start = phone.substring(0, 2);
    if (start === '+7') return phone;

    return `${phone.substring(0, 2)} (${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(8, 10)}-${phone.substring(
        10,
        12
    )}`;
};

export const getEnumList = (enums: any, getLabel?: Function) => {
    let list: Array<IEnumItem> = [];
    Object.values(enums).map((key) => {
        if (!isNaN(Number(key))) {
            list.push({ label: getLabel ? getLabel(key) : key, value: Number(key) });
        }
    });

    return list;
};

export function toUrl(data: any) {
    return encodeURIComponent(JSON.stringify(data));
}

var timeout: any = undefined;
export function delayAction(callbac: () => void, ms?: number) {
    if (!ms) ms = 700;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(callbac, ms);
}

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {} as Record<K, T[]>);

export const toFinanceString = (value: number | undefined, decimalPrecision = 2) => {
    if (!value) return value;

    return value.toLocaleString('ru-RU', { minimumFractionDigits: decimalPrecision, maximumFractionDigits: decimalPrecision });
};
