import React from 'react';

import { Tooltip, Form, Tag, Flex, Divider } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

import { HoldIcon, BoxesIcon, AirplaneIcon } from '@icons/index';
import { IBoxGroup } from '@src/core/entities/box-group';
import { ICountry } from '@src/core/entities/country';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IBoxGroupCard {
    country: ICountry;
    boxGroups: Array<IBoxGroup>;
}

const BoxGroupCard = (props: IBoxGroupCard) => {
    const { country, boxGroups } = props;

    const renderCountryCard = () => {
        let count = boxGroups.length + 1;

        return boxGroups.map((bg) => {
            --count;

            return (
                <Form colon={false} key={bg.id} labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }}>
                    <Form.Item
                        label={
                            <Tooltip title='Количество'>
                                <BoxesIcon />
                            </Tooltip>
                        }
                        className='country-form-item'
                        style={{ fontWeight: 600 }}
                    >
                        <Flex align='center' style={{ marginBottom: 2 }}>
                            <Tag color='purple' style={{ marginBottom: 2, fontSize: 15 }}>
                                {bg.itemName}
                            </Tag>
                            <div style={{ fontSize: 18 }}>
                                {bg.qty}
                                {bg.onHold && (
                                    <Tooltip placement='top' title={bg.onHoldReason} color='darkorchid'>
                                        <HoldIcon style={{ color: 'darkorchid', fontSize: 18, marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </div>
                        </Flex>
                    </Form.Item>
                    {bg.loadingOn && (
                        <Form.Item
                            label={
                                <Tooltip title='Дата отгрузки'>
                                    <CalendarOutlined style={{ fontSize: 18 }} />
                                </Tooltip>
                            }
                            className='country-form-item'
                        >
                            <span style={{ backgroundColor: '#FFDD2D', padding: '0 4px' }}>
                                {dayjs.utc(bg.loadingOn).local().format('DD.MM.YYYY')}
                            </span>
                        </Form.Item>
                    )}
                    {bg.awbNumber && (
                        <Form.Item label='AWB' className='country-form-item'>
                            <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{bg.awbNumber}</span>
                        </Form.Item>
                    )}
                    {bg.cargoName && (
                        <Form.Item label={<AirplaneIcon style={{ fontSize: 14, marginLeft: 5 }} />} className='country-form-item'>
                            <Tag color='var(--main-green)'>{bg.cargoName}</Tag>
                        </Form.Item>
                    )}

                    {count > 1 && (
                        <Form.Item wrapperCol={{ xs: 24 }} style={{ marginTop: -4 }} className='country-form-item'>
                            <Divider style={{ margin: 0 }} />
                        </Form.Item>
                    )}
                </Form>
            );
        });
    };

    return (
        <Flex
            vertical={true}
            style={{
                border: `1px solid ${country.color || 'var(--main-green)'}`,
                borderRadius: 6,
                width: 280,
                height: 'fit-content',
            }}
        >
            <div
                style={{
                    fontSize: 16,
                    fontWeight: 600,
                    background: country.color || 'var(--main-green)',
                    color: '#ffffff',
                    textAlign: 'center',
                }}
            >
                {country.name}
            </div>
            <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>{renderCountryCard()}</div>
        </Flex>
    );
};

export default BoxGroupCard;
