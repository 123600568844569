import React, { useState, useEffect } from 'react';

import { useElementSize } from 'usehooks-ts';

import { Row, Col, DatePicker, notification, Divider } from 'antd';
import { ReloadOutlined, PhoneFilled } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IContact } from '@entities/contact';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IContactGroup {
    cityName: string;
    contacts: Array<IContact>;
}

const Contacts = () => {
    const d = useAppDispatch();

    const { RangePicker } = DatePicker;

    const [containerRef, { width, height }] = useElementSize();

    const [api, contextHolder] = notification.useNotification();

    const [headers, setHeaders] = useState<Array<IContactGroup>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setHeaders([]);

        setLoading(true);

        serverFetch('contacts', { method: 'GET' })
            .then((data) => {
                setHeaders(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения контактов', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => setRefreshRequired(true),
                    },
                ]}
            />
        );
    };

    const renderContact = (contact: IContact) => {
        return (
            <p style={{ marginLeft: 5, fontSize: 16 }}>
                <u style={{ fontWeight: 500 }}>{contact.workingPosition}:</u>
                <div>{contact.fullName}</div>
                <div>
                    <PhoneFilled style={{ marginRight: 5 }} />
                    {contact.phone}
                </div>
            </p>
        );
    };

    const renderHeader = (header: IContactGroup) => {
        return (
            <>
                <div
                    style={{
                        backgroundColor: 'var(--primary-color)',
                        padding: '4px 10px',
                        fontWeight: 700,
                        fontSize: 18,
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    {header.cityName}
                </div>
                <div
                    style={{
                        marginBottom: 40,
                    }}
                >
                    {header.contacts.map((c) => renderContact(c))}
                </div>
            </>
        );
    };

    return (
        <div ref={containerRef}>
            <Row>{renderToolbar()}</Row>
            <Row>
                <Col span={10}>{headers.map((h) => renderHeader(h))}</Col>
            </Row>
        </div>
    );
};

export default Contacts;
