export enum WeightType {
    Volume = 1,
    Gross = 2,
}

export const enumLabel = (value?: WeightType) => {
    switch (value) {
        case WeightType.Volume:
            return 'Объемный вес';
        case WeightType.Gross:
            return 'Вес брутто';
        default:
            return '';
    }
};
