import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Input, Form, Row, Col, Divider } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';
import Trackpoints from './trackpoints';

import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { useAppDispatch } from '@store/hooks';
import { ITruck } from '@entities/truck';
import { TruckStatus, enumLabel } from '@enums/truck-status';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Truck = () => {
    const { id } = useParams();
    const [api, contextHolder] = notification.useNotification();

    const { TextArea } = Input;

    const d = useAppDispatch();

    const [entity, setEntity] = useState<ITruck>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);

        serverFetch(`trucks/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
            });
    }, []);

    return (
        <>
            <Row>
                <FormHeader title='Информация о машине' />
            </Row>

            {!loading && (
                <>
                    <Row>
                        <Col span={12}>
                            <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 10 }}>
                                <Form.Item initialValue={entity?.number} label='Номер машины' name='number' wrapperCol={{ span: 5 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item label='Статус' wrapperCol={{ span: 5 }}>
                                    <Input disabled={true} defaultValue={enumLabel(entity?.status)} />
                                </Form.Item>
                                <Form.Item initialValue={entity?.driverPhone} label='Телефон водителя' name='driverPhone'>
                                    <MaskedInput disabled={true} size='middle' mask={'+7 (000) 000-00-00'} />
                                </Form.Item>
                                <Form.Item label='Комментарий' name='comment' initialValue={entity?.comment}>
                                    <TextArea disabled={true} rows={4} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                    {entity?.status !== TruckStatus.New && (
                        <>
                            <Divider orientation='left'>Трекинг</Divider>
                            <Row style={{ marginBottom: 15, marginTop: 15 }}>
                                <Col offset={1} span={15}>
                                    <Trackpoints truckId={id} viewOnly={true} />
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}

            {contextHolder}
        </>
    );
};

export default Truck;
