export enum UnitType {
    Weight = 1,
    Pallet = 2,
    Cart = 3,
}

export const enumLabel = (value?: UnitType) => {
    switch (value) {
        case UnitType.Weight:
            return 'Вес';
        case UnitType.Pallet:
            return 'Паллета';
        case UnitType.Cart:
            return 'Телега';
        default:
            return '';
    }
};
