import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { isMobile } from 'react-device-detect';

import NotSupport from '@components/not-support/not-support';

import Auth from '@components/auth/auth';
import ResetPassword from '@components/auth/reset-password';

import Orders from '@components/orders/orders';
import Order from '@components/orders/order';

import ImportTrucks from '@components/import/trucks';
import ImportTruck from '@components/import/truck';
import ImportConsignments from '@components/import/consignments';

import DeliveryTrucks from '@components/delivery/trucks';
import DeliveryTruck from '@components/delivery/truck';

import Remainings from '@components/remainings/remainings';

import Notifications from '@components/notifications/notifications';

import Contacts from '@components/contacts/contacts';

import UserAccount from '@components/user-account/user-account';
import Balances from '@components/balances/balances';

import BillHeaders from '@components/bills/bill-headers';

import TransactionsHistory from '@components/transactions-history/transactions-history';

import { useAppSelector } from '@store/hooks';

import MainLayout from './main-layout';

import '@src/core/index.css';
import { IUserSession } from '@entities/user-session';
import { UserType } from '@enums/user-type';

declare global {
    interface Window {
        accessToken: string;
    }
}

const App = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    if (isMobile) return <NotSupport />;

    if (!userSession) return <Auth />;

    if (userSession.resetPasswordRequired) return <ResetPassword />;

    return (
        <Routes>
            <Route path='/' element={<MainLayout />}>
                {userSession.type == UserType.MainClient ? (
                    <Route index element={<Navigate replace to={`orders`} />} />
                ) : (
                    <Route index element={<Navigate replace to={`import`} />} />
                )}

                <Route path='orders' element={<Orders />} />
                <Route path='orders/:id' element={<Order />} />

                <Route path='import' element={<ImportTrucks />} />
                <Route path='import/:id' element={<ImportTruck />} />
                <Route path='import/consignments/:truckId' element={<ImportConsignments />} />

                <Route path='delivery' element={<DeliveryTrucks />} />
                <Route path='delivery/:id' element={<DeliveryTruck />} />

                <Route path='remainings' element={<Remainings />} />

                <Route path='notifications' element={<Notifications />} />

                <Route path='contacts' element={<Contacts />} />

                <Route path='useraccount' element={<UserAccount />} />

                <Route path='billheaders' element={<BillHeaders />} />

                <Route path='transactions' element={<TransactionsHistory />} />

                <Route path='balances' element={<Balances />} />
            </Route>
        </Routes>
    );
};

export default App;
