import React from 'react';
import Icon from '@ant-design/icons';

const PickupIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 20 , ...props.style }} />;
};
export default PickupIcon;

const svg = ({ ...props }) => (
    <svg {...props} fill='currentColor' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.93 56.93'>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <path d='M48.654,40.217l-11.771,5.697l-4.547-9.395l11.771-5.697L48.654,40.217z M45.801,16.3l9.722,20.089 l-5.885,2.848l-9.723-20.088L45.801,16.3z M40.618,47.512l16.312-7.604l-0.381-1.603L38.864,46.51 c-0.838-0.271-1.756-0.332-2.675-0.11c-0.16,0.037-0.317,0.084-0.471,0.135L23.575,23.577c1.507-1.646,0.112-5.235-2.842-4.859 c-0.291,0.037-0.396,0.068-0.603,0.087c-2.797-0.194-5.6-1.117-8.139-2.459c-0.016-0.294-0.028-0.587-0.045-0.881 c-0.148-2.42-1.555-4.553-4.191-4.704c-2.205-0.126-4.852,1.764-4.704,4.191c0.35,5.73,0.346,7.134,0.328,12.627l-0.057-0.002 c0.035,0.73,0.069,1.94,0.107,2.672c0.055,1.136,0.455,2.048,1.053,2.735c0.005,0.008,0.006,0.02,0.013,0.026 c0.707,1.087,0.65,2.241,1.215,3.403c-1.043,4.955-4.331,9.906-5.605,14.812c-0.934,3.603,4.624,5.127,5.558,1.532 c1.284-4.941,5.213-9.938,6.266-14.934c0.054-0.254,0.073-0.498,0.069-0.729c3.46,3.575,5.784,9.387,6.221,14.463 c0.316,3.678,6.082,3.706,5.764,0c-0.729-8.468-5.046-16.685-11.671-21.777c-0.021-0.426-0.041-0.854-0.062-1.278l0.022,0.002 c0.01-3.447,0.018-2.288-0.05-5.734c2.385,0.913,4.895,1.494,7.413,1.687l-0.004,0.07c0.894-0.007,1.467-0.071,2.35-0.183 c0.164-0.021,0.307-0.063,0.451-0.104l11.761,23.151c-1.419,1.167-2.139,3.076-1.686,4.979c0.631,2.662,3.312,4.312,5.977,3.682 c2.662-0.634,4.312-3.314,3.68-5.977C41.918,49.043,41.36,48.164,40.618,47.512z M37.722,52.854 c-0.898,0.213-1.803-0.344-2.016-1.24c-0.214-0.897,0.344-1.804,1.24-2.017c0.896-0.214,1.803,0.345,2.016,1.241 C39.175,51.737,38.62,52.64,37.722,52.854z M38.93,20.127l4.548,9.395l-11.771,5.697l-4.548-9.394L38.93,20.127z M7.667,0.745 c2.392,0,4.331,1.939,4.331,4.331s-1.939,4.331-4.331,4.331S3.336,7.468,3.336,5.076S5.275,0.745,7.667,0.745z'></path>
            </g>
        </g>
    </svg>
);
