export enum WarehouseType {
    Main = 1,
    Transit = 2,
}

export const enumLabel = (value?: WarehouseType) => {
    switch (value) {
        case WarehouseType.Main:
            return 'Основной';
        case WarehouseType.Transit:
            return 'Транзитный';
        default:
            return '';
    }
};
